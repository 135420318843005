<template>
    <div class="nav-item has-dropdown">
        <a v-if="isMobile" @click="toggleDropdown">{{ $t(title) }}</a>
        <router-link v-else :to="getRoute(menuItem.route_name)">{{ $t(title) }}</router-link>
        <ul class="nav-dropdown" :class="{'is-active': dropdown}" ref="navDropdown">
            <li>
                <div class="nav-item has-return">
                    <a @click="toggleDropdown">
                        Terug
                    </a>
                </div>
            </li>
            <li
                v-for="submenu_item in subMenuItems"
                v-if="allowedRoute(submenu_item.router_name, $store.getters['user/user'])">
                <div class="nav-item">
                    <router-link
                        :key="submenu_item.title"
                        :to="getRoute(submenu_item.route_name)"
                    >
                        {{ $t(submenu_item.title) }}
                    </router-link>
                </div>
            </li>
        </ul>
    </div>
</template>

<script>

import {allowedRoute, getRoute} from "../menu";

export default {
    props: {
        menuItem: {
            type: Object,
            required: true,
        },
        subMenuItems: {
            type: Array,
            required: true,
        },
        title: {
            type: String,
            required: true,
        },
    },

    data() {
        return {
            dropdown: false,
            isMobileView: window.innerWidth < 1024
        }
    },

    methods: {
        allowedRoute,
        getRoute,
        toggleDropdown(event) {
            event.preventDefault();

            this.dropdown = !this.dropdown;

            if(this.dropdown) {
                this.$emit('dropdown-opened');

                this.$nextTick(() => {
                    // Set .nav-dropdown scroll position to the top
                    this.$refs.navDropdown.scrollTop = 0;
                });
            }
        },

        handleResize() {
            const wasMobile = this.isMobileView;
            this.isMobileView = window.innerWidth < 1024;

            if (wasMobile && !this.isMobileView) {
                this.dropdown = false;
            }
        }
    },

    computed: {
        isMobile() {
            return this.isMobileView;
        }
    },

    created() {
        window.addEventListener('resize', this.handleResize);
    },

    beforeDestroy() {
        window.removeEventListener('resize', this.handleResize);
    },

    watch: {
        $route() {
            this.dropdown = false
        }
    }
}
</script>


<style scoped lang="scss">

</style>
