<template>
    <tab-content title="Overzicht">
        <div class="columns">
            <div class="column is-6 is-4-widescreen">
                <div class="tab-subheader">Bedrijfsgegevens</div>

                <small>Naam</small>
                <p>{{ this.company?.name ?? 'Onbekend' }}</p>

                <small>Bedrijfsnaam</small>
                <p>{{ this.company?.name_registered ?? 'Onbekend' }}</p>

                <small>Kvk-nummer</small>
                <p>
                    <a v-if="this.company?.coc_validation_url"
                       :href="this.company?.coc_validation_url"
                       class="external" target="_blank" rel="noopener noreferrer">{{ this.company?.coc }}</a>
                    <span v-else>Onbekend</span>
                </p>

                <small>Btw-nummer</small>
                <p>
                    <a v-if="this.company?.vat_validation_url"
                       :href="this.company?.vat_validation_url"
                       class="external" target="_blank" rel="noopener noreferrer">{{ this.company?.vat }}</a>
                    <span v-else>Onbekend</span>
                </p>

                <small>Registratiedatum</small>
                <p>{{ this.company ? $d(new Date(this.company?.created_at), 'long') : '' }}</p>
            </div>

            <div class="column is-6 is-6-widescreen">
                <div class="tab-subheader">Contactgegevens</div>

                <small>Contactpersoon</small>
                <p>{{ this.company?.contact ?? 'Onbekend' }}</p>

                <small>Telefoonnummer</small>
                <p>{{ this.company?.phone ?? 'Onbekend' }}</p>

                <small>Website</small>
                <p>
                    <a v-if="this.company?.site"
                       :href="this.company?.site"
                       class="external" target="_blank" rel="noopener noreferrer">{{ this.company?.site }}</a>
                    <span v-else>Onbekend</span>
                </p>

                <small>Landingspagina</small>
                <p>
                    <a v-if="this.company?.slug"
                       :href="`https://cadeaubon.gifty.nl/${this.company?.slug}`"
                       class="external" target="_blank" rel="noopener noreferrer">{{ this.company?.slug }}</a>
                    <span v-else>Onbekend</span>
                </p>

                <small>Adres</small>
                <p v-if="this.company?.street && this.company?.house_number">
                    {{ this.company?.street }} {{ this.company?.house_number }} {{ this.company?.addition }}<br>
                    {{ this.company?.zip }} {{ this.company?.city }} {{ this.company?.country_code }}
                </p>
                <p v-else>Onbekend</p>
            </div>
        </div>

        <h3 class="tab-header">Notities</h3>

        <div class="comments">
            <div class="comment create-comment">
                <div class="avatar" :style="{backgroundColor: $store.getters['user/user'].color}">{{ $store.getters['user/user'].first_name.slice(0, 2) }}</div>

                <g-form
                    :form="this.form"
                    :action="`/api/admin/company/${this.$route.params.company_id}/comment`"
                    @success="appendComment"
                    class="comment-form"
                >
                    <g-input
                        label="Notitie toevoegen"
                        type="textarea"
                        class="inputarea"
                        name="comment"
                        autosize
                    />

                    <div class="comment-actions">
                        <!--                        <i class="icon add-attachment">attach_file_add</i>-->
                        <g-button type="submit" :is-rounded="false">Toevoegen</g-button>
                    </div>
                </g-form>
            </div>

            <div v-for="comment in comments" class="comment">
                <div v-if="comment.user" class="avatar" :class="{'is-pinned': comment.pinned_at}" :style="{backgroundColor: comment.user.color}">{{ comment.user.first_name.slice(0, 2) }}</div>
                <div v-else class="avatar system" :class="{'is-pinned': comment.pinned_at}" ><img src="../../../../images/logo.png" /></div>
                <div class="meta">
                    <strong class="name">{{ comment.user ? `${comment.user.first_name} ${comment.user.last_name}` : 'Systeem'}} </strong>
                    <div class="date">
                        {{ $d(new Date(comment.created_at), 'long') }}
                        <span class="controls">
                            <span v-if="comment.user && comment.user.id === $store.getters['user/id']"> | <a href="#" @click.prevent="deleteComment(comment)">verwijderen</a></span>
                            <span> | <a href="#" @click.prevent="pinComment(comment)">📌</a></span>
                        </span>
                    </div>
                </div>
                <div class="comment-content">
                    <p><vue-simple-markdown :source="comment.comment" /></p>
                </div>
            </div>
        </div>
    </tab-content>
</template>

<script>
import TabContent from "../../../components/TabView/TabContent.vue";

export default {
    name: "Overview",
    components: {TabContent},
    data() {
        return {
            company: null,
            comments: null,
            form: new Form({
                comment: '',
                attachments: [],
            })
        }
    },
    mounted() {
        axios.get(`/api/admin/company/${this.$route.params.company_id}`).then(({data}) => {
            this.company = data.data;
        });

        axios.get(`/api/admin/company/${this.$route.params.company_id}/comment`).then(({data}) => {
            this.comments = data.data;
        });
    },
    methods: {
        appendComment(comment) {
            this.form.comment = '';
            this.comments = [comment.data, ...this.comments];
        },
        deleteComment(comment) {
            if (confirm('Weet je zeker dat je deze notitie wilt verwijderen?')) {
                axios.delete(`/api/admin/company/${this.$route.params.company_id}/comment/${comment.id}`).then(() => {
                    this.comments = this.comments.filter(c => c.id !== comment.id);
                });
            }
        },
        pinComment(comment) {
            let pinned = null;

            if(comment.pinned_at === null) {
                pinned = new Date().toISOString();
            }

            axios.patch(`/api/admin/company/${this.$route.params.company_id}/comment/${comment.id}`, {
                pinned_at: pinned
            }).then(() => {
                // Add the date to the comment
                comment.pinned_at = pinned;

                // Resort the comments by pinned date (newest first) and then by created_at date (newest first)
                this.comments = this.comments.sort((a, b) => {
                    if (a.pinned_at && b.pinned_at) {
                        return new Date(b.pinned_at) - new Date(a.pinned_at);
                    } else if (a.pinned_at) {
                        return -1;
                    } else if (b.pinned_at) {
                        return 1;
                    } else {
                        return new Date(b.created_at) - new Date(a.created_at);
                    }
                });
            });
        }
    }
}
</script>

<style scoped lang="scss">
$avatarSize: 1.875rem;

.comments {

    .comment {
        margin-left: calc($avatarSize / 2);
        padding-left: $avatarSize;
        padding-bottom: 0.875rem;
        border-left: 2px solid #EFEFEF;
        position: relative;

        &:hover {

            .meta .date .controls {
                opacity: 1;
            }
        }

        &:last-of-type {
            padding-bottom: 0.1rem;

            .comment-content {
                border-bottom: 0;
            }
        }

        .avatar {
            color: #FFFFFF;
            font-weight: bold;
            font-size: .95rem;
            text-transform: uppercase;
            line-height: 1.1;
            text-align: center;
            padding: .4rem 0;
            width: $avatarSize;
            height: $avatarSize;
            margin-left: -$avatarSize * 1.5;
            border-radius: 0.3125rem;
            box-shadow: inset 0 0 0.5rem -.2rem rgba(0, 0, 0, 0.20);
            outline: 5px solid #FFFFFF;
            display: block;
            position: absolute;

            &.is-pinned {
                // Animate the border outline color in rainbow colors
                animation: rainbow 5s infinite;

                @keyframes rainbow {
                    0% { outline-color: #FF0000; }
                    16.666% { outline-color: #FF8000; }
                    33.333% { outline-color: #FFFF00; }
                    50% { outline-color: #00FF00; }
                    66.666% { outline-color: #00FFFF; }
                    83.333% { outline-color: #0000FF; }
                    100% { outline-color: #FF00FF; }
                }

            }

            &.system {
                padding: 0 0.22rem 0 0.3rem;
                background: #DE134E;

                img {
                    width: 100%;
                    height: 100%;
                    object-position: center center;
                    object-fit: contain;
                }
            }
        }

        .meta {
            font-size: 0.75rem;
            line-height: 1.3;
            margin-bottom: 0.5rem;
            position: relative;

            .date .controls {
                opacity: 0;
            }

            a {
                color: hsl(0deg, 0%, 29%);
            }

            .name {
                color: #1F1F1F;
            }
        }

        &.create-comment {

            &:last-child .inputarea {
                margin-bottom: 0;
            }

            .comment-form {
                max-width: 32rem;
                position: relative;
            }

            .inputarea {

            }

            .comment-actions {
                padding: 0.61rem;
                position: absolute;
                bottom: 0;
                right: 0;

                .add-attachment {
                    cursor: pointer;
                    font-size: 1.2rem;
                    vertical-align: middle;
                    padding: 0.3rem;
                }
            }
        }

        .comment-content {
            max-width: 32rem;
            border-bottom: 1px solid #FAFAFA;
            p {
                color: #1F1F1F;
                font-size: 0.875rem;
                white-space: pre-wrap;
            }
        }
    }
}
</style>
