<template>
    <tab-content title="Drukwerk">
        <div class="columns column-form is-desktop">
            <div class="column is-7-widescreen is-offset-3-desktop is-offset-2-widescreen">
                <div class="notification is-warning">
                    <span>Bestellingen op deze pagina worden <strong>niet</strong> gefactureerd.</span>
                </div>
            </div>
        </div>

        <div class="columns column-form is-desktop">
            <div class="column is-3-desktop is-2-widescreen">
                <div class="tab-subheader">Winkelbonnen</div>
            </div>
            <div class="column is-7-widescreen">
                <div
                    v-if="!company.company_locations || company.company_locations?.length <= 0"
                    class="notification is-warning"
                >
                    <span>Dit bedrijf heeft geen vestigingen.</span>
                </div>

                <g-form v-else
                        :action="`/api/admin/company/${this.$route.params.company_id}/restocking`"
                        :form="form"
                        :before="beforeForm"
                        @success="success"
                        message="De bonnen zijn besteld">
                    <g-select
                        label="Vestiging"
                        name="location_id"
                        :options="storeLocations"
                    />

                    <g-input type="number" name="quantity_gift_cards" label="Aantal" min="1"/>

                    <g-select
                        label="Verpakking"
                        name="wrapping_id"
                        :options="this.wrappings"
                    />

                    <g-input type="textarea" name="comment" label="Opmerking(en) voor drukkerij"/>

                    <g-button
                        :isDisabled="!(form.quantity_gift_cards > 0)"
                        type="submit">Bestellen
                    </g-button><br /><br />
                </g-form>
            </div>
        </div>

        <div class="columns column-form is-desktop">
            <div class="column is-3-desktop is-2-widescreen">
                <div class="tab-subheader">Grootzakelijk</div>
            </div>
            <div class="column is-7-widescreen">
                <g-form :action="`/api/admin/order/system`"
                        :class="['loadable', { 'is-loading': packageLoading }]"
                        :form="systemForm"
                        :before="beforeSystemForm"
                        @success="afterSystemForm"
                        @fail="failedSystemForm"
                        message="De bonnen zijn besteld">

                    <g-select :options="orderTypes" label="Type bestelling" name="type"/>

                    <g-select :options="locales" label="Taal" name="locale"/>

                    <g-input label="Aantal" min="1" name="quantity" pattern="\d" type="number"/>

                    <g-select v-if="packages.length" :options="giftcardTypes" label="Type bon"
                             name="giftcard_type"/>
                    <g-format ref="format" label="Bedrag" name="amount" type="currency"
                              v-show="systemForm.giftcard_type === 'amount'"/>
                    <g-select v-show="systemForm.giftcard_type !== 'amount'"
                              :options="packages"
                              label="Arrangement"
                              name="package_id"
                              namekey="title"
                              valuekey="id"/>
                    <g-select :options="expirationOptions"
                              label="Geldigheid"
                              name="expiration"/>

                    <g-input label="Bericht" name="message" type="textarea"/>
                    <g-input label="Referentie" name="reference" rows="1" type="textarea"
                             maxlength="190"/>
                    <g-switch label="Deze bonnen markeren als promotioneel" name="promotional"/>
                    <g-button type="submit">Bestellen</g-button>
                </g-form>
            </div>
        </div>
    </tab-content>
</template>

<script>
import forEach from 'lodash/forEach'
import {formatCurrencyInput} from '../../../utils/Consts'
import Layout from '../../../components/Layout/Layout'
import {mapGetters} from "vuex";
import TabContent from "../../../components/TabView/TabContent.vue";

export default {
    name: 'printing',
    data: function () {
        return {
            systemForm: new Form({
                company_id: null,
                type: '1',
                giftcard_type: 'amount',
                amount: 0,
                package_id: null,
                quantity: '',
                message: '',
                reference: '',
                expiration: null,
                locale: null,
                promotional: 0,
            }),
            packageLoading: true,
            packages: [],
            orderTypes: [
                {name: 'Drukwerk', value: '1'},
                {name: 'E-mail', value: '0'},
            ],
            giftcardTypes: [
                {name: 'Arrangement', value: 'package'},
                {name: 'Bedrag', value: 'amount'},
            ],
            expirationOptions: [
                {
                    value: null,
                    name: 'Onbeperkt',
                },
                {
                    value: 30,
                    name: '1 maand',
                },
                {
                    value: 30 * 2,
                    name: '2 maanden',
                },
                {
                    value: 30 * 3,
                    name: '3 maanden',
                },
                {
                    value: 30 * 4,
                    name: '4 maanden',
                },
                {
                    value: 30 * 5,
                    name: '5 maanden',
                },
                {
                    value: 30 * 6,
                    name: '6 maanden',
                },
                {
                    value: 30 * 7,
                    name: '7 maanden',
                },
                {
                    value: 30 * 8,
                    name: '8 maanden',
                },
                {
                    value: 30 * 9,
                    name: '9 maanden',
                },
                {
                    value: 30 * 10,
                    name: '10 maanden',
                },
                {
                    value: 30 * 11,
                    name: '11 maanden',
                },
                {
                    value: 365,
                    name: '1 jaar',
                },
                {
                    value: 730,
                    name: '2 jaar',
                },
                {
                    value: 1095,
                    name: '3 jaar',
                },
                {
                    value: 1460,
                    name: '4 jaar',
                },
                {
                    value: 1825,
                    name: '5 jaar',
                },
                {
                    value: 2190,
                    name: '6 jaar',
                },
                {
                    value: 2555,
                    name: '7 jaar',
                },
                {
                    value: 2920,
                    name: '8 jaar',
                },
                {
                    value: 3285,
                    name: '9 jaar',
                },
                {
                    value: 3650,
                    name: '10 jaar',
                },
            ],
            wrappings: [],
            form: new Form({
                quantity_gift_cards: '',
                wrapping_id: null,
                comment: '',
                location_id: null,
            }),
            company: [],
        }
    },

    components: {
        TabContent,
        Layout
    },

    created() {
        this.fetchData()
    },

    methods: {
        fetchData() {
            axios.get('/api/admin/company/' + this.$route.params.company_id).then((response) => {
                this.company = response.data.data
                this.systemForm.expiration = response.data.data.gift_card_expiration;
                this.systemForm.locale = response.data.data.locale;
                this.form.comment = response.data.data.default_store_order_comment ?? ''
                this.wrappings = [
                    {
                        value: null,
                        name: 'Geen'
                    },
                    ...response.data.data.wrappings
                ]
            })

            this.packageLoading = true
            axios.get(`/api/company/${this.$route.params.company_id}/packages`).then(({data}) => {
                this.packageLoading = false
                this.packages = data.data
            })
        },
        success() {
            this.fetchData()
        },
        failedSystemForm() {
            this.packageLoading = false
        },
        beforeForm(form) {
            if (form.wrapping_id === null) {
                delete form.wrapping_id
            }

            return form
        },
        beforeSystemForm(form) {
            this.packageLoading = true

            form.company_id = this.$route.params.company_id

            if (form.giftcard_type === 'amount') {
                form.amount = formatCurrencyInput(this.$refs.format.getRawValue())
            }
            return form
        },
        afterSystemForm() {
            this.packageLoading = false

            forEach(this.systemForm.originalData, (value, key) => {
                if (key !== 'expiration') {
                    this.systemForm[key] = value
                }
            })
        }
    },

    computed: {
        ...mapGetters({
            locales: 'application/availableLocalesTranslated',
        }),
        storeLocations() {
            return this.company.company_locations.map((location) => {
                return {
                    value: location.id,
                    name: `${location.street} ${location.house_number}, ${location.city} (${location.blanco_giftcards_count})`
                }
            });
        },
    },

    watch: {
        giftcardType(value) {
            this.systemForm.package_id = null
            this.systemForm.amount = ''
        }
    }
}
</script>
