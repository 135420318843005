import {authenticationApi} from '../../api/Authentication'
import * as Sentry from '@sentry/vue'
import i18n from '../../utils/i18n'
import posthog from "posthog-js";

const state = () => ({
    user:              null,
    companyActive:     null,
    companyObjects:    {},
    companyObjectList: [],
    permissions:       null,
    features:          null,
    lastUpdated:       null,
})

// getters
const getters = {
    isAuthenticated:         state => !!state.user,
    isImpersonated:          state => state.user ? state.user.is_impersonated ?? false : false,
    isImpersonatedUser:      state => state.user ? state.user.is_impersonated_user ?? false : false,
    isGiftyAdmin:            state => state.user ? state.user.is_gifty_admin === true : false,
    permissions:             state => Object.keys(state.permissions).filter(permission => state.permissions[permission]),
    hasPermission:           (state) => (permission) => state.permissions ? state.permissions[permission] === true : false,
    features:                state => Object.keys(state.features).filter(feature => state.features[feature]),
    hasFeature:              (state) => (feature) => state.features ? state.features[feature] === true : false,
    id:                      (state) => state.user ? state.user.id : null,
    email:                   (state) => state.user ? state.user.email : null,
    user:                    (state) => state.user,
    allCompanies:            state => state.companyObjectList.map(id => state.companyObjects[id]),
    activeCompany:           (state) => state.companyActive ? state.companyObjects[state.companyActive] : null,
    activeCompanyId:         (state) => state.companyActive ? state.companyActive : null,
    activeCompanyDeprecated: (state) => state.companyActive ? state.companyObjects[state.companyActive].deprecated_id : null,
    requiresUpdate:          state => !!!state.lastUpdated,
}

// actions
const actions = {
    login ({commit, state}, user) {
        commit('setUser', null)
        commit('setCompanies', [])
        commit('setCompanyActive', null)

        return authenticationApi
            .login(
                user.email,
                user.password,
            )
    },

    // Gifty employee logged in as company
    impersonatedLogout({commit, getters, dispatch}) {
        return authenticationApi.adminLogout()
            .catch(() => {})
            .finally(() => {
                return dispatch('getUserData')
            })
    },

    logout({commit, getters, dispatch}) {
        return authenticationApi
            .logout()
            .finally(() => {
                commit('setUser', null)
                commit('setCompanies', [])
                commit('setCompanyActive', null)
            })
    },

    getUserData ({commit}) {
        return authenticationApi
            .user()
            .then(({data}) => {
                commit('setUser', data.data)
                commit('setCompanies', data.data.companies ?? [])
                commit('setCompanyActive', null)
            })
            .catch(error => {
                commit('setUser', null)
                commit('setCompanies', [])
                commit('setCompanyActive', null)

                return Promise.reject(error)
            })
    },

    setCompanyActive ({commit, state}, companyId) {
        commit('setCompanyActive', companyId)
    },
}

// mutations
const mutations = {
    setUser (state, user) {
        state.user        = user
        state.lastUpdated = Date.now()

        // Setting locale (update if the database value differs from the local value)
        if (
            (
                user !== null &&
                (typeof user.locale !== 'undefined' && typeof localStorage.getItem('locale') === 'undefined')
            )
            || user !== null && localStorage.getItem('locale') !== user.locale
        ) {
            // Update localStorage locale with database value
            localStorage.setItem('locale', user.locale)
            i18n.locale = user.locale
        }

        if (process.env.NODE_ENV === 'production') {
            if (user === null) {
                Sentry.configureScope(scope => scope.setUser(null));

                posthog.reset()
            } else {
                Sentry.setUser({
                    id:         user.id,
                    email:      user.email,
                    ip_address: '{{auto}}',
                })

                posthog.identify(
                    user.id,
                    {
                        email: user.email,
                    }
                );
            }
        }
    },

    setCompanies (state, companies) {
        state.companyObjects    = companies.reduce((obj, object) => {
            obj[object.id] = object

            return obj
        }, {})
        state.companyObjectList = companies.map(object => object.id)

        /**
         * Ensure the activeCompanyId, when set, is still included in the list of companies attached to the entity,
         * removing it otherwise to prevent the API from returning any error(s)
         */
        if (
            true  === localStorage.hasOwnProperty('activeCompanyId') &&
            false === state.companyObjectList.includes(localStorage.getItem('activeCompanyId'))
        ) {
            localStorage.removeItem('activeCompanyId')
        }
    },

    setCompanyActive (state, companyId) {
        const storedCompany = localStorage.getItem('activeCompanyId')
        state.permissions   = []
        state.features      = []

        if (
            // Set the stored active company from a previous session
            companyId === null &&
            state.companyObjectList.includes(storedCompany)
        ) {
            state.companyActive = storedCompany
            window.axios.defaults.headers.common['X-Gifty-Account'] = storedCompany;
        } else if (
            // Set the provided company id
            companyId !== null &&
            state.companyObjectList.includes(companyId)
        ) {
            localStorage.setItem('activeCompanyId', companyId)

            state.companyActive = companyId
            window.axios.defaults.headers.common['X-Gifty-Account'] = companyId;
        } else {
            // Set the first available company as active
            state.companyActive = state.companyObjectList[0] ?? null
            window.axios.defaults.headers.common['X-Gifty-Account'] = state.companyObjectList[0] ?? null;
        }

        if (state.companyActive !== null) {
            state.permissions = state.companyObjects[state.companyActive].permissions
            state.features = state.companyObjects[state.companyActive].features
        }

        if (state.user && state.user.is_gifty_admin === true) {
            state.permissions.admin_gifty = true
        }
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
