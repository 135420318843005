<template>
    <tab-content title="Bestelmodule">
        <g-form
            :action="`/api/admin/company/${this.$route.params.company_id}`"
            :prepopulate="true"
            @prepopulated="prepopulated"
            :form="this.form"
            message="Instellingen opgeslagen"
            method="patch"
        >
            <div class="columns column-form is-desktop">
                <div class="column is-3-desktop is-2-widescreen">
                    <div class="tab-subheader">Publicatie</div>
                </div>
                <div class="column is-4-widescreen">
                    <div class="field-header">URL landingspagina</div>
                    <div class="field-body">
                        <g-input name="slug" label="cadeaubon.gifty.nl/"></g-input>
                    </div>

                    <div class="field-body">
                        <g-switch name="is_published" label="Zichtbaar op gifty.nl"></g-switch>
                    </div>
                </div>
            </div>

            <div class="columns column-form is-desktop">
                <div class="column is-3-desktop is-2-widescreen">
                    <div class="tab-subheader">Voorwaarden</div>
                </div>
                <div class="column is-4-widescreen">
                    <div class="field-header">Aanvullende voorwaarden</div>
                    <g-file name="additional_terms" label="PDF-bestand" accept="application/pdf"/>
                    <g-switch name="additional_terms_enabled" label="Aanvullende voorwaarden actief"/>
                    <p v-if="this.terms_url !== null">
                        <a :href="this.terms_url" rel="noreferrer" target="_blank" class="external">Voorwaarden
                            bekijken</a>
                    </p>
                </div>
            </div>

            <div class="columns column-form is-desktop">
                <div class="column is-3-desktop is-2-widescreen">
                    <div class="tab-subheader">Instellingen</div>
                </div>
                <div class="column is-4-widescreen">
                    <div class="field-header">Verpakkingen</div>
                    <div class="field-body">
                        <g-switch name="display_standard_sleeves" label="Algemene verpakkingen tonen"></g-switch>
                    </div>
                </div>
            </div>

            <div class="columns column-form is-desktop">
                <div class="column is-3-desktop is-2-widescreen">
                    <div class="tab-subheader">Kleuren</div>
                </div>
                <div class="column is-4-widescreen">
                    <div class="columns">
                        <div class="column">
                            <div class="field-header">Primair</div>
                            <div class="field-body">
                                <g-colorpicker name="primary_color"
                                               :suggestions="this.form.color_suggestions"/>
                            </div>
                        </div>
                        <div class="column">
                            <div class="field-header">Knoppen</div>
                            <div class="field-body">
                                <g-colorpicker name="button_color"
                                               :suggestions="this.form.color_suggestions"/>
                            </div>
                        </div>
                    </div>

                    <div class="columns">
                        <div class="column">
                            <div class="field-header">Toggle achtergrond</div>
                            <div class="field-body">
                                <g-colorpicker name="toggle_color"
                                               :suggestions="this.form.color_suggestions"/>
                            </div>
                        </div>
                        <div class="column">
                            <div class="field-header">Toggle icon</div>
                            <div class="field-body">
                                <g-colorpicker name="toggle_foreground_color"
                                               :suggestions="this.form.color_suggestions"/>
                            </div>
                        </div>
                    </div>

                    <div class="columns">
                        <div class="column">
                            <div class="field-header">Succes-kleur</div>
                            <div class="field-body">
                                <g-colorpicker name="success_color"
                                               :suggestions="this.form.color_suggestions"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="columns">
                <div class="column is-offset-3-desktop is-offset-2-widescreen">
                    <g-button type="submit">Opslaan</g-button>
                </div>
            </div>
            <br/>
        </g-form>

        <h3 class="tab-header">Installatie</h3>

        <div class="columns column-form is-desktop">
            <div class="column is-3-desktop is-2-widescreen">
                <div class="tab-subheader">Gegevens</div>
            </div>
            <div class="column is-7-widescreen">
                <div class="field-header">Integratiecode</div>
                <p>{{ this.form.code }}</p><br/>


                <div class="field-header">Integratiescript</div>
                <pre>
<code id="code-script-widget">&lt;script&gt;
window.Gifty = { key: '{{ this.form.code }}' };
(function (e, t) {
	var n = e.createElement(t);
	n.async = true;
	n.src = 'https://static.gifty.nl/js/widget.js';
	var r = e.getElementsByTagName(t)[0];
	r.parentNode.insertBefore(n, r);
})(document, 'script');
&lt;/script&gt</code></pre>
                <br/>
            </div>
        </div>

        <div class="columns column-form is-desktop">
            <div class="column is-3-desktop is-2-widescreen">
                <div class="tab-subheader">Verpakkingen</div>
            </div>
            <div class="column is-7-widescreen">
                <div class="sleeves-box-scrollable">
                    <div :key="index" v-for="(wrapping, index) in filtered_wrappings">
                        <img :src="wrapping.image"
                             :alt="wrapping.name">
                        <p>{{ wrapping.name }}</p>
                        <hr>
                    </div>
                </div>
            </div>
        </div>

        <div class="columns column-form is-desktop">
            <div class="column is-3-desktop is-2-widescreen">
                <div class="tab-subheader">Voorbeeld</div>
            </div>
            <div class="column is-7-widescreen">
                <div data-gifty></div>
            </div>
        </div>
    </tab-content>
</template>

<script>
import TabContent from "../../../components/TabView/TabContent.vue";

export default {
    components: {TabContent},
    giftyScriptPointer: null,
    name: "Widget",
    data() {
        return {
            terms_url: null,
            wrappings: [],
            form: new Form({
                color_suggestions: [],
                is_published: null,
                slug: null,
                display_standard_sleeves: null,
                additional_terms: null,
                additional_terms_enabled: null,
                code: '',
                primary_color: null,
                toggle_color: null,
                toggle_foreground_color: null,
                button_color: null,
                success_color: null,
            })
        }
    },
    computed: {
        filtered_wrappings() {
            return this.wrappings.filter(wrapping => {
                return wrapping.name !== 'default_envelope' &&
                    wrapping.name !== 'Geen'
            })
        }
    },
    methods: {
        prepopulated({data}) {
            this.wrappings = data.wrappings;

            if (this.form.additional_terms !== null) {
                this.terms_url = this.form.additional_terms
                this.form.additional_terms = null
            }
        },
    },
    mounted() {
        if (window.Gifty === undefined) {
            window.Gifty = {trigger: false, tutorial: true};

            this.giftyScriptPointer = document.createElement('script');
            this.giftyScriptPointer.src = 'https://static.gifty.nl/js/widget.js';
            this.giftyScriptPointer.async = true;
            this.giftyScriptPointer.defer = true;

            document.head.appendChild(this.giftyScriptPointer);
        }

        const widgetTimer = window.setInterval(() => {
            if (window.Gifty !== undefined && window.Gifty.setKey !== undefined && this.form.code !== '') {
                window.clearInterval(widgetTimer)
                window.Gifty.setKey(this.form.code)
            }
        }, 800)
    },
    beforeDestroy() {
        if (this.giftyScriptPointer !== null) {
            document.head.removeChild(this.giftyScriptPointer);
        }
    },
}
</script>


<style lang="scss" scoped>
.sleeves-box-scrollable {
    max-height: 300px;
    overflow-y: scroll;
}
</style>

