<template>
    <tab-content title="Instellingen">
        <g-form
            :action="`/api/admin/company/${this.$route.params.company_id}`"
            :prepopulate="true"
            :form="this.form"
            message="Instellingen opgeslagen"
            method="patch"
            ref="mainForm"
        >
            <div class="columns column-form is-desktop">
                <div class="column is-3-desktop is-2-widescreen">
                    <div class="tab-subheader">Algemeen</div>
                </div>
                <div class="column is-4-widescreen">
                        <div class="field-header">Primaire taal</div>
                        <p><small>De primaire taal is de standaard taal die gebruikt wordt voor het bedrijf.</small></p>
                        <g-select
                            name="locale"
                            :options="locales"
                        />

                        <div class="field-header">Lidmaatschapsgroep</div>
                        <g-select
                            name="membership_package_id"
                            :options="membershipPackages"
                            valuekey="id"
                        />

                        <div class="field-header">Affiliate</div>
                        <g-select
                            name="referral"
                            :options="affiliateOptions"
                            valuekey="id"
                        />

                        <div class="field-header">Rechten</div>
                        <g-switch
                            name="is_collaborative"
                            label="Samenwerkingsaccount"
                            subtitle="Dit account heeft beperkte toegangsrechten tot instellingen en het plaatsen van bestellingen"
                        />

                        <br/>
                </div>
            </div>

            <div class="columns column-form is-desktop mt-5">
                <div class="column is-3-desktop is-2-widescreen">
                    <div class="tab-subheader">Functionaliteit</div>
                </div>
                <div class="column is-4-widescreen">
                        <div class="field-header">Verrekenoverzicht</div>
                        <g-switch label="'Overzicht per medewerker' zichtbaar" name="enable_employee_settlement"/>

                        <g-button class="mt-5" type="submit">Opslaan</g-button>
                </div>
            </div>
        </g-form>
    </tab-content>
</template>

<script>
import {mapGetters} from "vuex";
import TabContent from "../../../components/TabView/TabContent.vue";

export default {
    name: "Overview",
    components: {TabContent},
    data() {
        return {
            membershipPackages: [],
            affiliateOptions: [
                {
                    id: null,
                    name: 'Geen',
                }
            ],
            form: new Form({
                locale: null,
                is_collaborative: null,
                membership_package_id: null,
                referral: null,
                enable_employee_settlement: 0,
            }),
        }
    },
    mounted() {
        axios.get(`/api/admin/membership/packages`).then(({data}) => {
            this.membershipPackages = data.data
        })

        axios.get(`/api/admin/affiliate`).then(({data}) => {
            data.data.forEach((affiliate) => {
                this.affiliateOptions.push({
                    id: affiliate.id,
                    name: affiliate.slug
                })
            })
        })
    },
    computed: {
        ...mapGetters({
            locales: 'application/availableLocalesTranslated',
        }),
    }
}
</script>

<style scoped lang="scss">

</style>
